import * as firebase from 'firebase'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAgmbzh_yb_5JjYZlI42cVB044rhCZbzdE",
    authDomain: "irlhumanities-newsite.firebaseapp.com",
    databaseURL: "https://irlhumanities-newsite.firebaseio.com",
    projectId: "irlhumanities-newsite",
    storageBucket: "irlhumanities-newsite.appspot.com",
    messagingSenderId: "884717459890",
    appId: "1:884717459890:web:b9bce76dc07d1fbfa82cab",
    measurementId: "G-F43YDZQC8V"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp.firestore()