<template>
    <div class="transitionPage">
        <div v-if="person" id="Person">
            <div class="cols">
                <div class="photo-socials">
                    <img v-if="person.profileimage" :src="person.profileimage" alt="profile picture" class="propic">
                    <div class="socialmedia">
                        <p class="website"><a :href="person.website" target="_blank">{{ person.website }}</a></p>
                        <a v-if="person.socialmedia.twitter" :href="person.socialmedia.twitter" target="_blank"
                           class="socialmedia-set">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=euaEy0WeKJ0OBk6BbMEhF7XtEzC63ham"
                                 alt="twitter icon" class="socialmedia-icon">
                            <span> twitter</span>
                        </a>
                        <a v-if="person.socialmedia.instagram" :href="person.socialmedia.instagram" target="_blank"
                           class="socialmedia-set">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=KZM3sXQck2IQboR37Hu5pZDm19sPuVeC"
                                 alt="instagram icon" class="socialmedia-icon">
                            <span> instagram</span>
                        </a>
                        <a v-if="person.socialmedia.github" :href="person.socialmedia.github" target="_blank"
                           class="socialmedia-set">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=AkCjj2zdjeZw3QP4I5DN4T09RVCPriGy"
                                 alt="github icon" class="socialmedia-icon">
                            <span> github</span>
                        </a>
                        <a v-if="person.socialmedia.medium" :href="person.socialmedia.medium" target="_blank"
                           class="socialmedia-set">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=b8s33Pi3xVKoNqK01N9VlvzvTGAewOkw"
                                 alt="medium icon" class="socialmedia-icon">
                            <span> medium</span>
                        </a>
                        <a v-if="person.socialmedia.tumblr" :href="person.socialmedia.tumblr" target="_blank"
                           class="socialmedia-set">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=lypuKNT8wMoOMehWSQEquc5JpTcu72nS"
                                 alt="tumblr icon" class="socialmedia-icon">
                            <span> tumblr</span>
                        </a>
                        <a v-if="person.socialmedia.linkedin" :href="person.socialmedia.linkedin" target="_blank"
                           class="socialmedia-set">
                            <img src="https://awikhigan.irlh.org/gen/wl/?id=LQ1i4cHbkNgijZGjvvfOV9npodnpdfNQ"
                                 alt="linkedin icon" class="socialmedia-icon">
                            <span> linkedin</span>
                        </a>
                    </div>
                </div>
                <div class="name-bio">
                    <h1 class="name">{{ person.name }}</h1>
                    <div class="bio" v-html="person.bio"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import db from '../firebase.js'

    export default {
        name: "Person",
        data() {
            return {
                person: null
            }
        },
        created() {
            let ref = db.collection('labpeople').where('slug', '==', this.$route.params.person_slug);
            ref.get().then(snapshot => {
                snapshot.forEach(doc => {
                    this.person = doc.data();
                    this.person.id = doc.id;
                })
            })
        }
    }

</script>

<style scoped lang="scss">
    .transitionPage {
        background-color: #517B7B;
    }

    #Person {
        padding-top: 15vh;
    }

    a {
        color: white;

        &:hover {
            color: #ffc14e;
            text-decoration: underline;
        }
    }

    .cols {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 20px;
        width: 80%;
        max-width: 1400px;
        margin: 0 auto;
        @media screen and (max-width: 1024px) {
            max-width: 80%;
        }
        @media screen and (max-width: 800px) {
            display: flex;
            flex-direction: column;
            max-width: 80%;
        }
    }

    .photo-socials {
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        @media screen and (max-width: 800px) {
            grid-gap: 10px;
        }
    }

    .name-bio {
        display: flex;
        flex-direction: column;
        margin-top: 150px;
        box-shadow: 2px 2px 18px rgba(30, 30, 30, 0.6);
        padding: 18px 12px 6px 12px;
        background-color: #446a6a;
        @media screen and (max-width: 800px) {
            margin-top: 0;
        }
    }

    .name {
        font-family: "Roboto Slab", serif;
        font-size: 42px;
        font-weight: 300;
        text-align: left;
        margin: 0;
        padding-left: 12px;
        padding-bottom: 18px;
        border-bottom: 2px solid white;
    }

    .job {
        font-family: 'roboto-mono', monospace;
        font-size: 18px;
        font-weight: 300;
        margin: 0;
        padding-bottom: 18px;
        border-bottom: 1px solid #9cd4d4;
    }

    .bio {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        line-height: 1.7;
        // background-color: rgba(74, 43, 153, 0.75);
        padding: 6px 12px;
    }

    .propic {
        object-fit: cover;
        object-position: center;
        width: 250px;
        height: 250px;
        border-radius: 50%;
        border: 3px solid #9cd4d4;
        box-shadow: 0 0 16px rgba(66, 66, 66, 0.8);
        @media screen and (max-width: 800px) {
            width: 150px;
            height: 150px;
        }
    }

    .socialmedia {
        font-family: "Roboto Mono", monospace;
        font-size: 18px;
        font-style: normal;
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
        padding: 6px 12px;
        @media screen and (max-width: 800px) {
            grid-gap: 12px;
        }

        .socialmedia-set {
            display: flex;
            align-items: center;
        }

        .website {
            margin: 0;
        }
    }

    .socialmedia-icon {
        width: 22px;
        padding-right: 9px;
    }
</style>