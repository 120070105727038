<template>
    <div>
        <div class="labpeople">
            <div class="labperson" v-for="labperson in projectteam" :key="labperson.id">
                <div v-if="labperson.position === 'Grant Recipient'" class="labmember">
                    <router-link class="left" :to="{ name: 'Grants' }">
                        <img class="profilegrid" :src="labperson.profileimage" alt="">
                        <p class="position" style="color: #c481ff;"> {{ labperson.position }} </p>
                    </router-link>
                    <div>
                        <router-link :to="{ name: 'Grants' }">
                            <h3 class="name">{{ labperson.name }}</h3>
                            <p class="role" v-for="role in labperson.role" :key="role.id">{{ role }}</p>
                        </router-link>
                    </div>
                </div>
                <div v-if="labperson.position === 'Lab Member'" class="labmember">
                    <router-link class="left" :to="{ name: 'Person', params: { person_slug: labperson.slug } }">
                        <img class="profilegrid" :src="labperson.profileimage" alt="">
                        <p class="position"> {{ labperson.position }} </p>
                    </router-link>
                    <div>
                        <router-link :to="{ name: 'Person', params: { person_slug: labperson.slug } }">
                            <h3 class="name">{{ labperson.name }}</h3>
                            <p class="role" v-for="role in labperson.role" :key="role.id">{{ role }}</p>
                        </router-link>
                    </div>
                </div>
                <div v-if="labperson.position === 'External Collaborator'" class="labmember">
                    <div class="left">
                        <img class="profilegrid" :src="labperson.profileimage" alt="">
                        <p class="position" style="color: #ffc14e;"> {{ labperson.position }} </p>
                    </div>
                    <div>
                        <h3 class="name">{{ labperson.name }}</h3>
                        <p class="role" v-for="role in labperson.role" :key="role.id">{{ role }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import db from "@/firebase";

    export default {
        name: "team",
        data() {
            return {
                project: null,
                projectteam: []
            }
        },
        created() {
            let ref = db.collection('projects').where('slug', '==', this.$route.params.project_slug);
            ref.get().then(snapshot => {
                snapshot.forEach(doc => {
                    this.project = doc.data();
                    this.project.id = doc.id;

                    this.project.people = doc.data().projectteam;
                    for (var person in this.project.people) {

                        this.project.people.person = person;
                        // console.log(this.project.people["andrew-smith"].role);

                        let ref2 = db.collection('labpeople').where('slug', '==', this.project.people.person);
                        ref2.get().then(snapshot => {
                            snapshot.forEach(doc => {
                                this.teammember = doc.data();
                                this.teammember.role = this.project.people[this.teammember.slug].role;
                                this.teammember.order = this.project.people[this.teammember.slug].order;
                                this.projectteam.push(this.teammember);
                            })
                            this.projectteam = this.projectteam.sort((a, b) => {
                                let fa = a.order,
                                    fb = b.order

                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            })
                        })
                        let ref3 = db.collection('grantees').where('slug', '==', this.project.people.person);
                        ref3.get().then(snapshot => {
                            snapshot.forEach(doc => {
                                this.teammember = doc.data();
                                this.teammember.role = this.project.people[this.teammember.slug].role;
                                this.teammember.order = this.project.people[this.teammember.slug].order;
                                this.projectteam.push(this.teammember);
                            })
                            this.projectteam = this.projectteam.sort((a, b) => {
                                let fa = a.order,
                                    fb = b.order

                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            })
                        })
                        let ref4 = db.collection('externalcollaborators').where('slug', '==', this.project.people.person);
                        ref4.get().then(snapshot => {
                            snapshot.forEach(doc => {
                                this.teammember = doc.data();
                                this.teammember.role = this.project.people[this.teammember.slug].role;
                                this.teammember.order = this.project.people[this.teammember.slug].order;
                                this.projectteam.push(this.teammember);
                            })
                            this.projectteam = this.projectteam.sort((a, b) => {
                                let fa = a.order,
                                    fb = b.order

                                if (fa < fb) {
                                    return -1;
                                }
                                if (fa > fb) {
                                    return 1;
                                }
                                return 0;
                            })
                        })
                    }
                })
            })
        }
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: none;
    }

    .labpeople {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        grid-gap: 20px;
        @media screen and (max-width: 800px) {
            grid-gap: 30px;

        }
        .labmember {
            display: flex;
            flex-direction: row;
            grid-gap: 20px;
            @media screen and (max-width: 800px) {
                flex-direction: column;
                grid-gap: 0;
            }
        }
    }

    .profilegrid {
        width: 130px;
        height: 110px;
        object-fit: cover;
        object-position: center;
    }

    .left, .right {
        display: flex;
        flex-direction: column;
    }

    .name {
        font-family: Roboto Slab, serif;
        font-weight: 400;
        color: black;
        font-size: 22px;
        text-align: left;
        margin: 2px 0 8px 0;
    }

    .position {
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: #00b5b9;
        font-size: 13px;
        background-color: #252634;
        margin: -1px 0 0 0;
        text-align: center;
        width: 130px;
        padding: 2px 0;
    }

    .role {
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: black;
        font-size: 18px;
        text-align: left;
        margin: 0;
    }
</style>