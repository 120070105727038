<template>
    <div class="transitionPage">
        <div class="project">
            <div v-if="project" class="split">
                <div class="left">
                    <h1 v-html="project.title" class="title"></h1>
                    <ProjSummary/>
                </div>
                <div class="right">
                    <div class="floatingMenu">
                        <div class="sidebar">
                            <h2 class="links">Find Project at:</h2>
                            <div class="projectLinks">
                                <p v-if="project.projectsite">
                                    <a :href="project.projectsite"
                                       target="_blank" class="projectLink">
                                        <img :src="project.featuredimage"
                                             alt="url icon" class="linkIconP">
                                        <span> Project Site</span>
                                    </a>
                                </p>
                                <p v-for="link in project.findproject">
                                    <a :href="link.url"
                                       target="_blank" class="projectLink">
                                        <img :src="link.logo"
                                             alt="url icon" class="linkIcon">
                                        <span> {{ link.name }}</span>
                                    </a>
                                </p>
                            </div>
                            <h2>Project Team:</h2>
                            <team/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import team from "../components/team";
    import db from '../firebase.js'
    import ProjSummary from "@/components/ProjSummary";
    import extraCredits from "../components/extraCredits";

    export default {
        name: "ProjectV2",
        components: {
            ProjSummary,
            team,
            extraCredits
        },
        data() {
            return {
                project: null,
                summary: true,
                projectTeam: false
            }
        },
        created() {
            let ref = db.collection('projects').where('slug', '==', this.$route.params.project_slug);
            ref.get().then(snapshot => {
                snapshot.forEach(doc => {
                    this.project = doc.data();
                    this.project.id = doc.id;
                })
            })
        }
    }

</script>

<style scoped lang="scss">
    .transitionPage {
        background-color: #517B7B;
    }

    h1 {
        font-family: "Roboto Slab", serif;
    }

    img {
        max-width: 100%;
    }

    .project {
        padding-top: 15vh;
        margin: 0 auto;
        min-height: 85vh;

        .topContentContainer {
            padding-left: 20vw;
            padding-right: 20vw;
            @media screen and (max-width: 1600px) {
                padding-left: 10vw;
                padding-right: 10vw;
            }
        }

        .columns {
            display: flex;
            flex-direction: row;

            .col1, .col2 {
                display: flex;
                flex-basis: 50%;
                flex-direction: column;
            }

            .col1 {
                padding-right: 40px;
            }

            @media screen and (max-width: 1024px) {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .split {
        display: grid;
        grid-template-columns: 65% 35%;
        grid-gap: 80px;
        width: 80%;
        margin: 0 auto;
        @media screen and (max-width: 1100px) {
            grid-template-columns: 100%;
            grid-gap: 40px;
        }
    }

    .floatingMenu {
        background-color: #ffffff;
        color: black;
        position: sticky;
        top: 10vh;
        box-shadow: 4px 6px 12px rgba(26, 26, 26, 0.7);
        margin-bottom: 40px;
        max-height: 75vh;
        max-width: 500px;
        overflow-y: scroll;
        margin-top: 20px;
        @media screen and (max-width: 1100px) {
            max-height: none;
            max-width: 100%;
            margin-top: 0;
            position: relative;
            top: 0;
            overflow-y: hidden;
        }
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #c8c8c8;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #6c6c6c;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b57343;
    }

    .sidebar {
        padding: 20px 40px;

        h2 {
            font-weight: bold;
            font-size: 24px;
        }
    }

    .title {
        font-size: 42px;
        font-family: "Roboto Mono", monospace;
        letter-spacing: -1px;
        font-weight: 400;
        margin: 0;
        padding-bottom: 8px;
        border-bottom: 2px solid white;
    }

    .projectLinks {
        font-family: "Roboto Mono", monospace;
        font-size: 18px;

        .projectLink {
            display: flex;
            align-items: center;
            grid-gap: 10px;
            color: #6533ff;
            text-decoration: underline;
        }

        .linkIcon {
            width: 30px;
            padding-right: 9px;
        }

        .linkIconP {
            width: 30px;
            height: 30px;
            border-radius: 60px;
            object-fit: cover;
            border: 0.5px solid black;
        }

        a {
            color: black;
            font-weight: 400;

            &:hover {
                color: #00b5b9;
            }
        }
    }
</style>