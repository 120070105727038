<template>
    <div class="transitionPage">
        <div id="People">
            <h1>Team</h1>
            <labpeople/>
        </div>
    </div>
</template>

<script>
	import labpeople from "@/components/PeopleSections/labpeople";
	export default {
        name: "People",
		components: {labpeople}
	}
</script>

<style scoped lang="scss">
    .transitionPage {
        background-color: #517B7B;
    }

    #People {
        padding-top: 10vh;
        margin: 0 auto;
        padding-left: 10vw;
        padding-right: 10vw;
    }

    h1 {
        font-family: "Roboto Slab", serif;
        font-size: 42px;
        color: white;
        font-weight: 300;
        text-align: center;
		padding-bottom: 20px;
    }

</style>
<style lang="scss">

</style>