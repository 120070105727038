<template>
    <div class="transitionPage">
        <div class="grantees">
            <h1>irLh Grant Program</h1>
            <div class="programDescription">
                <p>irLh works with scholars, artists, creators, and professionals across industries to support the development of digital projects in the arts and humanities. The irLh Grant Program provides technical and design support and resources towards the creation of a grant recipient's projects.</p>
                <p>The program is not currently soliciting applications.</p>
            </div>
            <h2>Grant Projects & Recipients</h2>
            <div class="grantee" v-for="grantee in grantees" :key="grantee.id">
                <img class="projectimage" :src="grantee.projectimage">
                <div class="textArea">
                    <div class="name">{{ grantee.name }} | {{ grantee.projectTitle}}</div>
                    <div class="bio" v-html="grantee.bio"></div>
                    <div class="summary" v-html="grantee.projectsummary"></div>
                    <router-link :to="{ name: 'Project', params: { project_slug: grantee.projSlug } }">
                        <p style="margin: 0;">Click to learn more about the project</p>
                    </router-link>
                </div>
                <div class="person">
                    <img class="profileimage" :src="grantee.profileimage">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	import db from '../firebase.js'
	export default {
		name: "Grants",
		data() {
			return {
				grantees: []
			}
		},
		created() {
			let ref = db.collection('grantees');
			ref.get().then(snapshot => {
				snapshot.forEach(doc => {
					let grantee = doc.data();
					grantee.id = doc.id;
					this.grantees.push(grantee);
				})
				this.grantees = this.grantees.sort((a, b) => {
					let fa = a.order,
						fb = b.order

					if (fa < fb) {
						return -1;
					}
					if (fa > fb) {
						return 1;
					}
					return 0;
				})
			})
		}
	}
</script>

<style scoped lang="scss">

    .transitionPage {
        background-color: #517B7B;
    }
	h1 {
		font-family: "Roboto Slab", serif;
		font-size: 42px;
		color: white;
		font-weight: 300;
		text-align: center;
	}
	h2 {
		font-family: "Roboto Slab", serif;
		font-size: 32px;
		color: white;
		font-weight: 300;
		text-align: left;
	}
	a {
		text-decoration: none;
        color: #ffc14e;
        font-weight: 400;
	}
	.grantees {
		padding-top: 10vh;
		margin: 0 auto;
		padding-left: 20vw;
		padding-right: 20vw;
		@media screen and (max-width: 1600px) {
			padding-left: 10vw;
			padding-right: 10vw;
		}

	}
	.programDescription {
		color: white;
		font-family: Roboto Slab, serif;
		font-size: 18px;
		line-height: 1.7;
		width: 80%;
		margin: 0 auto;
        padding: 12px 18px;
        background-color: #446a6a;
        box-shadow: 4px 4px 18px rgba(25, 25, 25, 0.75);
	}

	.grantee {
		padding-bottom: 72px;
		display: flex;
		align-items: center;
		@media screen and (max-width: 1024px) {
			flex-direction: column;
			align-items: start;
		}
	}

	.projectimage {
        max-width: 100%;
		width: 400px;
		height: 300px;
		object-fit: cover;
		padding-right:20px;
		flex-basis: 30%;
	}

	.textArea {
		font-family: Roboto, sans-serif;
		color: white;
		font-size: 16px;
		line-height: 1.7;
		flex-basis: 50%;
		.name {
			color: white;
			font-family: Roboto Slab, serif;
			font-weight: 400;
			font-size: 24px;
		}
	}

	.person {
		flex-basis: 20%;
		.profileimage {
			width: 200px;
			height: 200px;
			object-fit: cover;
			padding-left:20px;
			@media screen and (max-width: 1024px) {
				display: none;
			}
		}

	}

</style>
