<template>
    <div class="nav">
        <div class="top-left">
            <span class="nav-left logo">
                <router-link :to="{ name: 'Home'}">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=ZFb6xybUw1HMbN8OGMRAEICqNSlUwtiW"
                         alt="irlhLogo"
                         class="logo">
                </router-link>
            </span>
        </div>
        <div class="desktopnav">
            <div class="top-right">
                <router-link :to="{ name: 'People'}">
                    <span class="nav-right people">team</span>
                </router-link>
                <router-link :to="{ name: 'Projects'}">
                    <span class="nav-right projects">projects</span>
                </router-link>
                <router-link :to="{ name: 'Grants'}">
                    <span class="nav-right grants">grant program</span>
                </router-link>
                <a href="https://salt.codes/" target="_blank"><span class="nav-right projects">publication</span></a>
            </div>
            <div class="bottom-right">
                <a href="https://twitter.com/irlhumanities" target="_blank">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=euaEy0WeKJ0OBk6BbMEhF7XtEzC63ham"
                         alt="twitter icon"
                         class="socialmedia-icon">
                </a>
                <a href="https://github.com/irlh" target="_blank">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=AkCjj2zdjeZw3QP4I5DN4T09RVCPriGy"
                         alt="github icon"
                         class="socialmedia-icon">
                </a>
                <a href="https://instagram.com/irlhumanities" target="_blank">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=KZM3sXQck2IQboR37Hu5pZDm19sPuVeC"
                         alt="instagram icon"
                         class="socialmedia-icon">
                </a>
            </div>
        </div>
        <div class="mobileNavContent">
            <div class="top-rightMobile">
                <div class="topnav">
                    <a href="javascript:void(0);" class="icon hamburgerMenu" v-on:click="openClose()">
                        <i class="large material-icons hamb">menu</i>
                    </a>
                    <div id="myLinks">
                        <router-link :to="{ name: 'People'}">
                            <p class="people">team</p>
                        </router-link>
                        <router-link :to="{ name: 'Projects'}">
                            <p class="projects">projects</p>
                        </router-link>
                        <router-link :to="{ name: 'Grants'}">
                            <p class="grants">grant program</p>
                        </router-link>
                        <a href="https://salt.codes/" target="_blank"><p class="projects">publication</p></a>
                        <div class="socialMediaMobile">
                            <a href="https://twitter.com/irlhumanities" target="_blank">
                                <img src="https://awikhigan.irlh.org/gen/wl/?id=euaEy0WeKJ0OBk6BbMEhF7XtEzC63ham"
                                     alt="twitter icon"
                                     class="socialmedia-icon">
                            </a>
                            <a href="https://github.com/irlh" target="_blank">
                                <img src="https://awikhigan.irlh.org/gen/wl/?id=AkCjj2zdjeZw3QP4I5DN4T09RVCPriGy"
                                     alt="github icon"
                                     class="socialmedia-icon">
                            </a>
                            <a href="https://instagram.com/irlhumanities" target="_blank">
                                <img src="https://awikhigan.irlh.org/gen/wl/?id=KZM3sXQck2IQboR37Hu5pZDm19sPuVeC"
                                     alt="instagram icon"
                                     class="socialmedia-icon">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Nav",
        methods: {
            openClose() {
                let x = document.getElementById("myLinks");
                if (x.style.display === "block") {
                    x.style.display = "none";
                } else {
                    x.style.display = "block";
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    $Offset: 32px;

    a {
        color: white;
    }

    .nav {
        font-family: Roboto Slab, serif;
        font-size: 24px;
        z-index: 10;

        .nav-left {
            padding-left: $Offset;
        }

        .top-left, .top-right, .bottom-left, .bottom-right {
            position: fixed;
            z-index: 10;
        }

        .top-right {
            right: 0;
            top: $Offset;
            background-color: rgba(0, 19, 16, 0.85);
            padding: 10px;
            display: flex;
            grid-gap: 20px;
            a {
                &:hover .nav-right {
                    color: #00b5b9;
                }
            }
        }

        .bottom-left {
            left: 0;
            bottom: $Offset;
        }

        .bottom-right {
            right: 32px;
            bottom: $Offset;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 24px;
        }
    }

    .desktopnav {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    .socialmedia-icon {
        z-index: 10;
        width: 28px;
        transition: 0.5s;

        @media screen and (max-width: 768px) {
            margin: 0 auto;
            text-align: center;
        }
        &:hover {
            transform: translateY(-10px) scale(1.2);
            transition: 0.5s;
        }
    }

    .logo {
        margin-top: -24px;
        width: 100px;
    }

    .mobileNavContent {
        display: none;
        @media screen and (max-width: 800px) {
            display: block;
        }

        .logoImg {
            padding-left: 16px;
        }

        .topnav {
            overflow: hidden;
            position: relative;
        }

        .topnav #myLinks {
            display: none;
            text-align: center;
        }

        .topnav a:hover {
            color: #00b5b9;
        }

        .hamb {
            font-size: 32px;
        }
    }

    .socialMediaMobile {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        grid-gap: 32px;
        text-align: center;
    }

    .hamburgerMenu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

    }
    .hamb {
        justify-self: center;
        align-self: center;
        text-align: center;
        margin: 0 auto;
    }

    .top-rightMobile {
        position: fixed;
        top: 20px;
        right: 20px;
        background-color: #005b5d;
        padding: 6px;
        border: 1px solid white;
        box-shadow: 3px 3px 8px rgba(41, 41, 41, 0.7);
    }
</style>