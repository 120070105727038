<template>
    <div class="transitionPage">
        <div id="Home">
            <div class="container">
                <vue-p5 class="p5draw" style="position: absolute; z-index:-1; display:block" @setup="setup"
                        @draw="draw"></vue-p5>
            </div>
            <div class="content">
                <div class="contentContainer">
                    <img src="https://awikhigan.irlh.org/gen/wl/?id=DUdTkz2EKAGupPXbshpyUc3gdpuFRMFx" alt="irlh-eye"
                         class="irlhEye">
                    <div class="titleText">
                        <h1 class="title">Immersive Realities Labs</h1>
                        <h2 class="subtitle">for the humanities</h2>
                        <div class="text">
                            <div>
                                <h3 class="caption">We are a collective of digital & experimental workgroups in the humanities, arts, and
                                    technology</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import VueP5 from "vue-p5"

    export default {
        name: 'Home',
        components: {
            "vue-p5": VueP5
        },
        methods: {
            setup(sketch) {

                sketch.createCanvas(sketch.windowWidth, sketch.windowHeight - 4);

                sketch.background(0, 50, 50);
                sketch.frameRate(10);
                sketch.a = 0;
                sketch.noiseScale = 0.018;
            },
            draw(sketch) {
                //let mouse = new sketch.Vector(sketch.mouseX,sketch.mouseY);
                sketch.ellipse(sketch.mouseX, sketch.mouseY, 100);
                sketch.stroke(0);
                sketch.line(sketch.pmouseX, sketch.pmouseY, sketch.mouseX, sketch.mouseY);
                sketch.fill(0, 50, 50, 50);
                sketch.rect(0, 0, sketch.width, sketch.height)
                sketch.fill(100, 210, 210, 120);
                sketch.noStroke();


                for (let i = 0; i < sketch.width; i += 30) {
                    for (let j = 0; j < sketch.height + 50; j += 30) {
                        let noiseVal = sketch.noise((i + sketch.a) * sketch.noiseScale, (j + sketch.a) * sketch.noiseScale);
                        sketch.fill(50 * noiseVal, 120 * noiseVal, 120 * noiseVal, 120);

                        //let vec = sketch.createVector((i-sketch.mouseX),(j-sketch.mouseY));

                        sketch.rect(i, j, 35 * noiseVal, 35 * noiseVal, 15 * noiseVal);

                    }
                }
                sketch.a += 0.75;
            }
        }

    };
</script>

<style scoped lang="scss">
    .transitionPage {
        @media screen and (max-width: 800px) {
            background-color: #005b5d;
        }
    }
    #Home {
        height: 100%;
    }
    .content {
        max-width: 100vw;
        width: 100%;
        margin-top: 15vh;
        text-align: center;
        display: inline-block;
        overflow: hidden;

        .contentContainer {
            background-color: rgba(0, 64, 64, 0.65);
            margin: 0 auto;
            max-width: 60%;
            padding: 40px 0;
            @media screen and (max-width: 1024px) {
                max-width: 80%;
            }
            @media screen and (max-width: 768px) {
                background-color: rgba(0, 64, 64, 0);
            }
        }
    }

    .irlhEye {
        display: block;
        margin: 0 auto;
    }

    .titleText {
        font-family: Roboto Slab, serif;
        padding-top: 24px;
        padding-bottom: 48px;
    }

    .title {
        font-size: 56px;
        margin: 8px 0 0 0;
        font-weight: 300;
        @media screen and (max-width: 800px) {
            line-height: 1.15;
            font-size: 48px;
        }
    }

    .subtitle {
        font-size: 36px;
        margin-top: 0;
        font-weight: 300;
        @media screen and (max-width: 800px) {
            font-size: 28px;
        }
    }

    .text {
        margin: 0 auto;
    }

    .caption {
        font-size: 20px;
        font-family: 'roboto-mono', monospace;
        font-weight: 300;
        margin: 0 auto;
        line-height: 1.7;
        max-width: 60%;
        @media screen and (max-width: 1920px) {
            max-width: 80%;
        }
        @media screen and (max-width: 1024px) {
            max-width: 100%;
        }
    }

    canvas {
        border: 0 none;
    }

    .container {
        width: 100%;
        @media screen and (max-width: 800px) {
            display: none;
        }
        .p5draw {
            width: 100%;
        }
    }
</style>