<template>
    <div class="labpeople">
        <div class="labperson" v-for="labperson in labpeople" :key="labperson.id">
            <router-link class="profilegrid" :to="{ name: 'Person', params: { person_slug: labperson.slug } }">
                <img class="profilegrid" :src="labperson.profileimage" alt="">
            </router-link>
            <router-link :to="{ name: 'Person', params: { person_slug: labperson.slug } }">
                <p class="name">{{ labperson.name }}</p>
<!--                <p class="job">{{ labperson.job }}</p>-->
            </router-link>
        </div>
    </div>
</template>

<script>
    import db from "@/firebase";

    export default {
        name: "labpeople",
        data() {
            return {
                labpeople: []
            }
        },
        created() {
            let ref = db.collection('labpeople');
            ref.get().then(snapshot => {
                snapshot.forEach(doc => {
                    let labperson = doc.data();
                    labperson.id = doc.id;
                    this.labpeople.push(labperson);
                })
            })
        }
    }
</script>

<style scoped lang="scss">
    a {
        text-decoration: none;
    }

    .labpeople {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding-bottom: 48px;
        grid-gap: 60px;

        .labperson {
            display: flex;
            flex-direction: column;
            padding-bottom: 24px;

            &:hover .name {
                color: #feffc9;
            }

            &:hover .job {
                color: #feffc9;
            }
        }
    }

    .profilegrid {
        width: 300px;
        height: 300px;
        object-fit: cover;
        margin: 0 auto;
        box-shadow: 5px 5px 12px rgba(30, 30, 30, 0.7);
    }

    .name, .job {
        font-family: Roboto Slab, serif;
        color: #fff;
        font-size: 20px;
        text-align: center;
        width: 300px;
    }

    .name {
        margin: 8px auto 0 auto;
        font-family: 'roboto-slab', serif;
        font-weight: 400;
    }

    .job {
        padding-bottom: 12px;
        margin: 4px auto 0 auto;
        font-size: 16px;
        font-family: 'roboto-mono', monospace;
    }
</style>