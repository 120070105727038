<template>
    <div class="summary" v-if="project.summary" v-html="project.summary">
    </div>
</template>

<script>
	import db from "@/firebase";

	export default {
		name: "ProjSummary",
		data() {
			return {
				project: null,
			}
		},
		created() {
			let ref = db.collection('projects').where('slug', '==', this.$route.params.project_slug);
			ref.get().then(snapshot => {
				snapshot.forEach(doc => {
					this.project = doc.data();
					this.project.id = doc.id;
				})
			})
		}
	}
</script>

<style scoped lang="scss">
	img {
		float:left;
		width: 450px;
		height: 300px;
		object-fit: cover;
		margin: 0 auto;
	}
	.summary {
		color: white;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		line-height: 1.7;
        margin-bottom: 40px;
	}

</style>

<style lang="scss">
    .summary {
        p {
            font-family: "Roboto Slab", serif;
            font-weight: 300;
            line-height: 1.25;
            font-size: 20px;
            .ptitle {
                font-style: italic;
                color: #38ffc3;
                font-weight: 400;
            }
        }
        a {
            color: #ffbf56;
            font-weight: 300;
        }

        .credits {
            margin-left: 18px;
            h3 {
                margin-bottom: 6px;
            }
            p {
                margin: 0 0 0 18px;
                font-size: 18px;
            }
        }

        .memory, .gallery, .prayer {
            padding-top: 12px;
            h2 {
                margin: 0 0 6px 0
            }
        }
    }
</style>