<template>
    <div class="transitionPage">
        <div class="projects">
            <h1>Projects</h1>
            <div class="projects-section">
                <div class="project" v-for="project in projects" :key="project.id">
                    <router-link class="projectgrid" :to="{ name: 'Project', params: { project_slug: project.slug } }">
                        <img class="projectgrid" :src="project.featuredimage" :alt="project.title + ' banner image'">
                    </router-link>
                    <router-link :to="{ name: 'Project', params: { project_slug: project.slug } }">
                        <p class="title" v-html="project.title"></p>
                        <p class="caption">{{ project.caption }}</p>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import db from "@/firebase";

    export default {
        name: "Projects",
        data() {
            return {
                projects: []
            }
        },
        created() {
            let ref = db.collection('projects');
            ref.get().then(snapshot => {
                snapshot.forEach(doc => {
                    let project = doc.data();
                    project.id = doc.id;
                    this.projects.push(project);
                })
                this.projects = this.projects.sort((a, b) => {
                    let fa = a.title.toLowerCase(),
                        fb = b.title.toLowerCase();

                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                })
            })
        }
    }
</script>

<style scoped lang="scss">
    .transitionPage {
        background-color: #517B7B;
    }
    .projects {
        padding-top: 10vh;
        margin: 0 auto;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    h1 {
        font-family: "Roboto Slab", serif;
        font-size: 42px;
        color: white;
        font-weight: 300;
        text-align: center;
        padding-bottom: 20px;
    }

    h2 {
        display: inline-block;
        font-family: "Roboto Slab", serif;
        font-size: 24px;
        color: white;
        font-weight: 300;
    }

    a {
        text-decoration: none;
    }

    .projects-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        grid-gap: 40px;
        padding-bottom: 40px;

        .project {
            max-width: 400px;
            background-color: #363636;
            display: flex;
            flex-basis: 25%;
            flex-direction: column;
            padding: 16px;
            &:hover .title {
                color: #ffff63;
            }
        }
    }

    .projectgrid {
        width: 100%;
        min-width: 325px;
        height: 275px;
        object-fit: cover;
        margin: 0 auto;
    }

    .title {
        font-family: Roboto Slab, serif;
        font-weight: 400;
        color: white;
        font-size: 22px;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 0;
    }

    .caption {
        font-family: 'roboto-mono', monospace;
        color: #dfdfdf;
        font-size: 16px;
        line-height: 1.25;
        margin: 8px 0;
    }
</style>

<style lang="scss">

</style>