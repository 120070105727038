import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../pages/Home.vue'
import People from "../pages/People";
import Person from "../pages/Person";
import Projects from "../pages/Projects";
import Project from "../pages/ProjectV2";
import Grants from "../pages/Grants";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/people/:person_slug',
        name: 'Person',
        component: Person
    },
    {
        path: '/team',
        name: 'People',
        component: People
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects
    },
        {
        path: '/projects/:project_slug',
        name: 'Project',
        component: Project
    },    
        {
        path: '/grant-program',
        name: 'Grants',
        component: Grants

    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
