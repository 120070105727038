<template>
	<div id="app">
		<Nav/>
		<router-view/>
	</div>
</template>

<script>
	import Nav from "./components/Nav";

	export default {
		name: 'App',
		components: {
			Nav
		}
	}
</script>

<style lang="scss">
    @import url("https://use.typekit.net/xds3rex.css");

    $irlhTheme: #00b5b9;

    @font-face {
        font-family: roboto-slab, serif;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: roboto-slab, serif;
        font-weight: 700;
        font-style: normal;
    }

    @font-face {
        font-family: roboto-mono, monospace;
        font-weight: 300;
        font-style: normal;
    }

	html {
        scroll-behavior: smooth;
	}
    body {
        margin: 0;
        font-family: Roboto, sans-serif;
        color: white;
        font-weight: 300;
    }
    #app {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100vh;
    }
    .transitionPage {
        height: 100%;
        width: 100%;
        flex-grow: 1;
    }

	a {
		color: $irlhTheme;
		text-decoration: none;
	}
    .bio {
        a {
            color: #ffc14e;
            &:hover {
                text-decoration: underline;
            }
        }
    }

	.VueCarousel-inner {
		justify-content: space-evenly;
	}
</style>
