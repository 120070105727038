<template>
	<div class="summary">
		<p>Below are additional credits beyond the core Project Team who have supported each performance of <em>Pray Daddy: The Prayer</em>.</p>
		<h2>Pray Daddy: The Prayer 2020 in Trinidad</h2>
		<div v-for= "person in people" v-if= "person.performance == 'trinidad'">			
			{{person.name}}, {{person.role}}
		</div>

		<h2>Pray Daddy: The Prayer 2020 at Harvard</h2>
		<div v-for= "person in people" v-if= "person.performance == 'harvard'">
			{{person.name}}, {{person.role}}
		</div>

		<h2>Pray Daddy: The Prayer 2017</h2>
		<div v-for= "person in people" v-if= "person.performance == '2017'">			
			{{person.name}}, {{person.role}}
		</div>
	</div>
</template>

<script>
	import db from "@/firebase";

	export default {
		name: "extraCredits",
		data() {
			return {
				people: [],
			}
		},
		created() {
			let ref = db.collection('praydaddycredits');
			ref.get().then(snapshot => {
				snapshot.forEach(doc => {
					let person = doc.data();
					person.id = doc.id;
					this.people.push(person)
				})
			})
		}
	}
</script>

<style scoped>
	
	.summary {
		color: white;
		font-family: Roboto, sans-serif;
		font-size: 18px;
		line-height: 1.7;
		padding-bottom: 40px;
	}
	h2{
		font-family: Roboto Slab, serif;
		font-weight: 300;
		color: white;
		font-size: 22px;
		text-align: left;
		padding-top:20px;
	}

</style>